<template>
  <v-card id="cw-confirmation-dialog--dialog__kreditz">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        What is Kreditz?
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        Kreditz offers individuals and companies the opportunity to quickly, securely and reliably
        check a customer's solvency. With the new PSD2 Payment Services Directive, we have moved to
        an Open banking model that ensures safer and more responsible operations in the financial
        sector. This means that the banks act together with a third party, in this case Kreditz,
        with the consent of the customer. In this way, the bank's identification service and
        interface information can be used to check the solvency, so the service is safe to use and
        the loan offer can be made on the basis of more detailed information.
      </p>
      <p v-translate class="ma-0">
        The service allows us to offer you very quickly a loan that better suits your ability to
        pay back the loan and without you having to send us salary or other proof of income.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogKreditz',
};
</script>
